import Axios from "axios";

export const useAxiosMiddleware = () => {
  const headers = {
    "Access-Control-Allow-Private-Network": "true",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_XAPI_KEY,
    client: "BMS",
    "Access-Control-Allow-Methods": "GET,OPTIONS,POST,PUT",
  };

  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: headers,
  });

  axios.interceptors.request.use(
    function (request) {
      request = {
        ...request,
        headers: {
          ...request.headers,
          Authorization: request?.Authorization
            ? request.Authorization
            : request.headers.Authorization,
        },
      };
      return Promise.resolve(request);
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return Promise.resolve(response);
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return { axios };
};
