import { useSelector, useDispatch } from "react-redux";
import { setClientToken } from "../store/reducers/user_store";
import useLogout from "./useLogout";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";

export const useRefreshToken = () => {
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const { setLocalStorageItem, getLocalStorageItem } = useLocalStorage();

  const initializeRefresh = async () => {
    const params = new URLSearchParams();
    try {
      let phone_number =
        getLocalStorageItem("phoneCode") + getLocalStorageItem("phoneNumber");
      let refreshToken = getLocalStorageItem("refreshToken");
      params.append("phone_number", phone_number);
      params.append("refresh_token", refreshToken);

      let headers = {
        client: "BMS",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer xcvghbljkhbhgf=",
        "X-Correlation-Id": "a8b7e592-8190-4ae2-af0b-d0863a5f87fe",
        Accept: "*/*",
        "Cache-Control": "no-cache",
      };
      let result = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/v1/refresh`,
        params,
        {
          headers: headers,
        }
      );
      if (
        result.status !== 200 ||
        !result?.data?.AuthenticationResult?.AccessToken
      ) {
        alert("Your session has expired, please login again");
        logout();
        return Promise.reject(null);
      }
      setLocalStorageItem(
        "accessToken",
        result.data.AuthenticationResult.AccessToken
      );
      dispatch(
        setClientToken({ token: result.data.AuthenticationResult.AccessToken })
      );
      return Promise.resolve(result.data.AuthenticationResult.AccessToken);
    } catch (error) {
      alert("Your session has expired, please login again");
      logout();
      return Promise.resolve(null);
    }
  };
  return { initializeRefresh };
};
